
$(document).ready(function(){
    /*
        hide/show aree homepage
    */
    $('.building-block-4').click(function(){slideArea($('.area-info-scuola'))});
    $('.building-block-3').click(function(){slideArea($('.area-info-arte'))});
    $('.building-block-2').click(function(){slideArea($('.area-info-impresa'))});
    $('.building-block-1').click(function(){slideArea($('.area-info-ricerca'))});
    /*
        hide/show infobox text
    */
    $('.infobox .btn-show-more').click(function(event){
        $(this).closest('.infobox').find('.infobox-short-text').toggleClass('short-text');
        $(this).closest('.infobox').find('.btn-show-more span').toggle();
        event.preventDefault();
    });


    /*
        hide/show area text
    */
    $('.area-tab-content .btn-show-more').click(function(event){
        $(this).closest('.area-tab-content').find('.area-tab-text').toggleClass('short-text');
        $(this).closest('.area-tab-content').find('.btn-show-more span').toggle();
        event.preventDefault();
    });


    /*
        scroll view to buildings
    */

   $('.btn.btn-main-more').click(function(event){
    var href = $(this).attr('href');
    var offset = $(this).data('offset');
    $(href).scrollView(offset);
    event.preventDefault();
});





    /*
        video modal
    */

    // Gets the video src from the data-src on each button

var $videoSrc;
$('.video-btn').click(function() {
    $videoSrc = $(this).data( "src" );
});
//console.log($videoSrc);



// when the modal is opened autoplay it
$('#myModal').on('shown.bs.modal', function (e) {

// set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
$("#video").attr('src',$videoSrc + "?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1" );
})


// stop playing the youtube video when I close the modal
$('#myModal').on('hide.bs.modal', function (e) {
    // a poor man's stop video
    $("#video").attr('src',$videoSrc);
})

    $('.btn-staff').click(function(){
        $('.btn-staff span').toggle();
        $('.more-staff').toggle();
        event.preventDefault();
    });

    $('.btn-partner').click(function(){
        $('.btn-partner span').toggle();
        $('.more-partner').toggle();
        event.preventDefault();
    });




/*
 Area Page Anchor Management
*/

if(window.location.hash && $('#area-tabs').length) {
    //console.log(window.location.hash+'tabs-lenght'+$('#area-tabs').length);
    $('#area-tabs a[href="'+window.location.hash+'"]').tab('show');
    var scrolllength=$('#area-tabs a[href="'+window.location.hash+'"]').offset().top - 50;

    $('html, body').animate({
        scrollTop: scrolllength
      }, 1000);

  } else {
    // Fragment doesn't exist
  }

});


function slideArea(area){
    if(area.is(":visible")){
        area.hide();
    }
    else{
        $('.area-info').hide();
        area.slideDown("slow");
        setTimeout($(area).scrollView(),500);
    }
}

$.fn.scrollView = function (offset) {
    if(!offset)offset=160;
    return this.each(function () {
      $('html, body').animate({
        scrollTop: $(this).offset().top - offset
      }, 1000);
    });
  }



